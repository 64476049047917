import React from "react"
import { Link } from 'gatsby'
import {Box, Container, Button, Typography, makeStyles, withStyles, List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import './index.css'
import bannerImage from '../assets/images/AmanPersonLogo.png'

const useStyles = makeStyles({
  headerText: {
    color: 'white',
    fontSize: '30px',
    fontWeight: '600'
  },
  extraBold: {
    fontWeight: 600
  },
  redText: {
    color: '#e22727'
  },
  checkIcon: {
    color: 'green',
    fontSize: '24px'
  },
  link: {
    textDecoration: 'none'
  }
});

const PurchaseButton = withStyles({
  root: {
    backgroundColor: '#e22727',
    color: '#fff',
    lineHeight: '40px',
    fontSize: '30px',
    borderRadius: '4px',
    padding: '16px 30px',
    '&:hover, &:focus': {
      backgroundColor: '#b31414',
      color: '#fff',
      boxShadow: 'inset 0px 0px 0px 2px #fff',
    }
  },
  
})(Button)

export default function FourBeatsDeal() {
  const classes = useStyles();

  return (
    <>
      <Box height={80} bgcolor="#e22727" display="flex" alignItems="center" justifyContent="center">
        <Typography className={classes.headerText}>Attention artists!</Typography>
      </Box>
      <Container>
        <Box display="flex" alignItems="center" justifyContent="center" pt={5}>
          <img src={bannerImage} alt="banner"/>
        </Box>
        <Typography component="div">
          <Box m={2} mt={0} fontSize={30} textAlign="center">
            Get 4 licensed beats for your next project, worth $280.
          </Box>
          <Box m={2} className={`${classes.extraBold} ${classes.redText}`} fontSize={46} textAlign="center"> 
            Yours for $15.
          </Box>
          <Box textAlign="center">
            Or, get 8 beats for $30.
          </Box>
          <Box m={2} fontSize={24} textAlign="center">
            You can make up to <strike>$46,000</strike> <span className={classes.redText}s>Unlimited $$$</span> in song sales and streams with this small investment.
          </Box>
        </Typography>

        <Box m={1} mt={4} mb={7} display="flex" alignItems="center" justifyContent="center">
          <Link to='/checkoutbeats' className={classes.link}><PurchaseButton>Purchase your beats now!</PurchaseButton></Link>
        </Box>

        <Typography component="div">
          <Box m={6} mt={9} fontSize={22} textAlign="center">
            Here's what you get:
          </Box>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className={classes.checkIcon} />
              </ListItemIcon>
              <ListItemText>
                <strong>4 beats</strong>, almost enough for an EP. You can drop a whole project with these beats. <strong>That's already insane value for $15.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className={classes.checkIcon} />
              </ListItemIcon>
              <ListItemText>
                <strong>HQ WAV Files.</strong> You'll receive the high quality WAV for each beat, completely untagged.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className={classes.checkIcon} />
              </ListItemIcon>
              <ListItemText>
                <strong>Unlimited License for each beat.</strong> That's an unlimited allowance of songs and sales on any platforms, performances, etc. <strong>That's for each beat.</strong>
              </ListItemText>
            </ListItem>
          </List>

          <Box m={6} mt={9} fontSize={24} textAlign="center">
            Think about your music career for a second, and how this can help tremendously.
          </Box>
          <Box m={2} textAlign="left"> 
            I think the most crippling thing for an artist is money. It's the age of the independent artist, and many of us are recording and releasing our music on our own. 
          </Box>
          <Box m={2}  textAlign="left">
            That means you're likely funding a lot of things out of your own pocket.
          </Box>
          <Box m={2}  textAlign="left">
            Are you one of those artists? If so, <strong>this beat deal is for you.</strong>
          </Box>

          <Box m={6} mt={9} fontSize={24} textAlign="center">
            Let's run through the numbers really quickly.
          </Box>
          <Box m={2} textAlign="left"> 
            Normally, each beat comes with a <strong>Commercial License</strong>, giving you an allowance of 5000 sales and 250,000 streams on any platform.
          </Box>
          <Box m={2} textAlign="left"> 
            Say that you price every song at $1. If you hit the maximum sales allowed, you'll hit <strong>$5000 in sales.</strong>
          </Box>
          <Box m={2} textAlign="left"> 
            Streaming is harder to calculate, but let's use a conservative $0.005 per stream, which is a rough number for Spotify's payout. 250,000 streams would put you at <strong>$1250 in streaming revenue.</strong>
          </Box>
          <Box m={2} textAlign="left"> 
            That's a total of <strong>$6250.</strong>
          </Box>
          <Box m={2} textAlign="left"> 
            That's just one song. There's 4 beats in this package. Multiplying $6250 by 4 would put you at $25,000. 
          </Box>
          <Box m={2} textAlign="left"> 
            Assuming you hustle and hit your sales and streaming numbers, that's a return of investment (ROI) of <strong>166,566.67%.</strong>
          </Box>
          <Box m={2} textAlign="left"> 
            <strong>You may never in your life see a return of investment this high. Stock traders, corporations, and beyond will NEVER get close to these kind of numbers in a single transaction.</strong>
          </Box>
          <Box m={2} textAlign="left"> 
            <strong>But wait!</strong> This beat pack gives you an unlimited allowance on streams and song sales. Forget the analysis we just did, <strong>you can make an infinite amount with this deal.</strong>
          </Box>
          <Box m={2} textAlign="left"> 
            This is one hell of an investment for yourself, all you need to do is follow through.
          </Box>
          
          
          <Box m={6} mt={9} fontSize={24} textAlign="center">
            These are the best beats I have to offer.
          </Box>
          <Box m={2} textAlign="left"> 
            Best is subjective, but I've hand picked some of my favorite beats and made them available with this deal. 
          </Box>
          <Box m={2} textAlign="left"> 
            Many of these beats sound great together, and that's by design. I basically hand picked these beats as if I was executive producing a project.
          </Box>
          <Box m={2} textAlign="left"> 
            If anything, I'm losing out greatly by selling these for so cheap.
          </Box>
          <Box m={2} textAlign="left"> 
            But why? Honestly, I'm at a point in my career where I want to see others succeed. Sometimes all you need is one break and a good start to change everything.
          </Box>
          <Box m={2} textAlign="left"> 
            I hope this beat pack is exactly what you need to make that turning point happen.
          </Box>
          
          <Box m={4} mt={9} fontSize={32} className={classes.redText} fontWeight={600} textAlign="center"> 
            If you've read this far, you already know how ridiculous this deal is.
          </Box>
          <Box m={4} fontSize={24} textAlign="center">
            Get your 4 beats and start releasing music commercially today!
          </Box>

          <Box m={1} mt={4} mb={7} display="flex" alignItems="center" justifyContent="center">
            <Link to='/checkoutbeats' className={classes.link}><PurchaseButton>Purchase your beats now!</PurchaseButton></Link>
          </Box>
          <Box m={4} mt={10} fontSize={14} textAlign="center"> 
            Copyright {(new Date()).getFullYear()}. www.ProdByAman.com
          </Box>
          
        </Typography>
      </Container>  
    </>
  );
}

